<template>
  <label v-bind:for="component_meta.variable" v-if="component_meta.component.properties.show_label == 'Y'">
    {{ component_meta.label }}：
  </label>
  <span class="tip" v-if="component_meta.component.properties.show_label == 'Y'">{{ component_meta.tip }}</span>

  <select v-bind:id="component_meta.variable" v-bind:name="component_meta.variable" multiple="multiple"
    v-model="component_value" v-bind:class="{ 'is-invalid': component_message != '' && component_message != undefined }">
    <template v-for="(item, key) in component_select" :key="key">
      <option v-bind:value="key">
        {{ item.title }}
      </option>
    </template>
  </select>
  <div class="invalid-mssage">{{ component_message }}</div>
</template>

<script>

import "jquery-ui-multiselect-widget/src/jquery.multiselect.js"
import "jquery-ui-multiselect-widget/src/jquery.multiselect.filter.js"
import "jquery-ui-multiselect-widget/css/jquery.multiselect.css"
import "jquery-ui-multiselect-widget/css/jquery.multiselect.filter.css"
import $ from 'jquery'
export default {
  props: ['field', 'value', 'message'],
  emits: ['input'],
  data() {
    let component_select = {};

    // 載入組件清單給下拉元件
    if (this.field.component.properties.source_json != '' && this.field.component.properties.source_json != undefined) {
      component_select = JSON.parse(this.field.component.properties.source_json);
      for (let key in component_select) {
        component_select[key] = { 'title': component_select[key] };
      }
    }
    else {

      let parameters = '';
      let params = {};

      if (this.field.component.properties.sort_rule_fields != undefined) {
        for (let i = 0; i < this.field.component.properties.sort_rule_fields.length; i++) {
          let variable = this.field.component.properties.sort_rule_fields[i].field_variable;
          let rule = this.field.component.properties.sort_rule_fields[i].rule;
          params['_s_[' + variable + ']'] = rule;
        }
      }

      if (this.field.component.properties.datazone != '' && this.field.component.properties.datazone != undefined) {
        params['dz'] = this.field.component.properties.datazone;
      }

      for (let pkey in params) {
        if (parameters != '') {
          parameters += '&';
        }
        parameters += `${pkey}=${params[pkey]}`;
      }

      if (parameters != '') {
        parameters = '?' + parameters;
      }

      let api_url = this.$microlink.base_api_url + this.field.component.properties.source_api + parameters;

      // 如果有設定快取，則從快取中取得資料
      if (this.field.component.properties.pool == 'Y') {

        let pool_key = this.$microlink.base64_encode(api_url);
        if (this.$microlink.get_data_pool(pool_key) == undefined) {
          component_select = this.$microlink.api(api_url);
          component_select = component_select.data;
          this.$microlink.set_data_pool(pool_key, component_select);
        }
        else {
          component_select = this.$microlink.get_data_pool(pool_key);
        }
      }
      else {
        component_select = this.$microlink.api(api_url);
        component_select = component_select.data;
      }
    }

    return {
      // 元件識別碼
      component_id: 'select-' + Math.random().toString().substring(2),

      // 欄位資訊
      component_meta: this.field,

      // 元件值
      component_value: (this.value == undefined) ? [] : this.value,

      // 元件選項
      component_select: component_select,

      // 元件訊息
      component_message: this.message
    }
  },
  watch: {
    message: function (new_value, _) {
      _;
      this.component_message = new_value;

    }
  },
  mounted() {
    let $this = this;
    $("#" + this.component_meta.variable).multiselect({
      buttonWidth: '100%',
      menuHeight: 300,
      // header:['全選','取消全選'],
      linkInfo: {
        'checkAll': {
          'class': 'ui-multiselect-all',
          'icon': '<span class="ui-icon ui-icon-check"></span>',
          'text': '全選',
          'title': '全選',
        },
        'uncheckAll': {
          'class': 'ui-multiselect-none',
          'icon': '<span class="ui-icon ui-icon-closethick"></span>',
          'text': '取消全選',
          'title': '取消全選',
        },
      },
      noneSelectedText: ' 請選擇',
      selectedText: ' 已選擇 # 項，共 # 項',
      click: function () {
        $this.component_value = [];
        $(this).multiselect("widget").find("input:checked").each(function () {
          $this.component_value.push($(this).val());
        });

        let $event = { 'temp': $this.component_value };
        $this.$emit('input', $event);
      }
    }).multiselectfilter({
      label: '關鍵字：',
      placeholder: ''
    });
  }
}
</script>

<style>
span.tip {
  color: green;
}

.ui-multiselect-header .ui-icon {
  margin-top: 1px;
  margin-right: 3px;
}

.ui-multiselect-checkboxes input[type="checkbox"] {
  margin: 0px 10px;
}

.ui-widget-header {
  background: none;
  background-color: #343a40;
  border: none;
}

.ui-state-active {
  color: #212529;
}

.ui-multiselect-filter {
  font-size: 15px;
  width: auto;
  margin-left: 10px;
  padding-top: 5px;
}

.ui-multiselect-header .ui-multiselect-filter input {
  font-size: 16px;
  width: 200px;
  height: 20px;
  padding: 5px;
  border-radius: 5px;
}

.ui-multiselect-hasfilter ul {
  top: 12px;
  margin-right: 10px;
  float: right;
}
</style>